import dynamic from "next/dynamic";
import Link from "next/link";

import { AppTitleHeader } from "../AppTitleHeader";

const DisplayDate = dynamic(
  () => import("@/components/ui/DisplayDate").then((mod) => mod.DisplayDate),
  { ssr: false }
);

export const Nav = () => (
  <nav
    className="absolute inset-0 flex items-center justify-between"
    data-testid="nav-component"
  >
    <div className="absolute left-16 inset-y-0 hidden lg:flex items-center">
      <DisplayDate dayClassNames="text-sm" dateClassNames="text-sm ml-2" />
    </div>
    <div className="text-left lg:text-center flex-1 block absolute left-14 lg:left-0 right-0">
      <Link
        passHref
        href="/"
        className="cursor-pointer inline-block z-10"
        data-testid="pc-header-name"
      >
        <AppTitleHeader />
      </Link>
    </div>
  </nav>
);
