import { NavOptions } from "@/components/ui/lite/NavOptions";

import { Nav } from "./Nav";

export const Header = () => (
  <div className="relative w-full bg-fi-black min-h-18 lg:min-h-28 text-white lg:mx-auto">
    <NavOptions />
    <Nav />
  </div>
);
